





























import { Component, Vue } from 'vue-property-decorator';
import RegisterLayout from '@/components/auth/register/RegisterLayout.vue';
import { CreateBoardMutation } from '@/graphql/board';
import { WorkspaceQuery } from '@/graphql/workspace';

@Component({
  components: { RegisterLayout },
})
export default class CreateBoardInput extends Vue {
  createBoardInput: string | null = '';
  clicked = false;
  successLoading = false;

  get workspaceId() {
    return this.$store.state.workspace.id;
  }
  createBoard() {
    this.$apollo
      .mutate({
        mutation: CreateBoardMutation,
        variables: {
          name: this.createBoardInput,
          workspace: this.workspaceId,
        },
        refetchQueries: [{ query: WorkspaceQuery, variables: { pid: this.$store.state.active_workspace_pid } }],
      })
      .then(({ data: { createBoard: board } }) => {
        this.successLoading = true;
        setTimeout(() => {
          this.$router.push({ name: 'board-layout', params: { board_pid: board.pid } });
        }, 1200);
      })
      .catch(() => {})
      .finally(() => {});
  }
}
