













































import { Component, Vue } from 'vue-property-decorator';
import CreateBoardInput from '@/components/board/CreateBoardInput.vue';
import { CreateBoardMutation } from '@/graphql/board';
import { WorkspaceQuery } from '@/graphql/workspace';
import GuidedCreateObjective from "@/components/board/objective/GuidedCreateObjective.vue";
import Board from "@/components/board/Board.vue";
import {PublicBoardTemplatePaginator} from "@/typescript/types";
import AhaMoment from "@/components/workspace/AhaMoment.vue";

@Component({
  components: {AhaMoment, Board, CreateBoardInput, GuidedCreateObjective },
})
export default class BoardCreate extends Vue {
  createBoardInput: string | null = null;
  clicked = false;
  successLoading = false;
  /* Permissions on the board, can be workspace or private */
  permissions = 'workspace';
  wait = false;
  isLoading: boolean = false;
  PublicBoardTemplatesData: PublicBoardTemplatePaginator | null = null;
  publicBoardTemplatesPageCount: number = 1;
  browseTemplates: boolean = false;
  defaultRoomName: string | null = 'Project #1';
  autoCreate: boolean = true;

  name: string | null = 'Room';
  individualSuggestions: Array<{ name: string }> = [
    {name: 'Traveling 🌴'},
    {name: 'Morning routine ☀️'},
    {name: 'Meditation 🧘'},
    {name: 'Bucket list 🧘'},
    {name: 'Workout 💪'},
    {name: 'Books 📚'},
    {name: 'Home'},
    {name: 'Marketing'},
    {name: 'Management'},
    {name: 'Finance'},
    {name: 'Sales'},
    {name: 'Design'},
    {name: 'Event planning'},
    {name: 'Website'},
  ];

  get workspaceId() {
    return this.$store.state.workspace.id;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  setSuggestedName(name: string) {
    this.createBoardInput = name;
    this.createBoard();
  }

  createBoard() {
    if(this.wait) {
      return
    }
    if(!this.createBoardInput) {
      this.createBoardInput = this.defaultRoomName;
    }
    this.successLoading = true;
    this.wait = true;
    setTimeout(() => this.wait = false, 1000);
    this.$apollo
      .mutate({
        mutation: CreateBoardMutation,
        variables: {
          name: this.createBoardInput,
          workspace: this.workspaceId,
          permissions: this.permissions,
          board_level_identifier: 2,
          sprint_interval: 2
        },
        refetchQueries: [{ query: WorkspaceQuery, variables: { pid: this.$store.state.active_workspace_pid } }],
      })
      .then(({ data: { createBoard: board } }) => {
        this.$store.commit('set_currently_onboarding', false);
        // this.$store.commit('set_currently_onboarding_in_room', true);
        this.$router.push({
          name: 'room', params: {
            board_pid: board.pid,
            workspace_pid: this.$store.state.active_workspace_pid,
          }
        });
        this.$gtag.event('pmb_app_onboarding_board_create_success');
      })
      .catch((_error) => {
        this.$gtag.event('pmb_app_onboarding_board_create_failed');
        
        this.autoCreate = false;
      })
      .finally(() => {
        this.successLoading = false;
      });
  }

  mounted() {
    if(this.autoCreate) {
      this.createBoard();
    }
    this.$gtag.event('pmb_app_onboarding_board_create_mount');
    this.$nextTick(function () {
      //@ts-ignore ToDo: fix type issue
      this.$refs.mainCreate.focus();
    });
  }
}
